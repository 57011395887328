import { render, staticRenderFns } from "./StoreUserInfo.vue?vue&type=template&id=faaaf498&scoped=true"
import script from "./StoreUserInfo.vue?vue&type=script&lang=js"
export * from "./StoreUserInfo.vue?vue&type=script&lang=js"
import style0 from "./StoreUserInfo.vue?vue&type=style&index=0&id=faaaf498&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faaaf498",
  null
  
)

export default component.exports